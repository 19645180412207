import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { About } from './About';
import { BookClub } from './BookClub';
import { Footer } from './Footer';
import { Guidance } from './Guidance';
import { Hero } from './Hero';
import { Toolbar } from './Toolbar';
import { ParallaxProvider } from 'react-scroll-parallax';
import Menu from './Sidebar';
import { BottomLine } from './Section';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const scrollRef = useRef(null);
  useEffect(() => {
    if (window.location.href.includes('bookclub')) {
      scrollRef.current.scrollIntoView();
    }
    return () => {};
  }, []);
  return (
    <ParallaxProvider>
      <MainContainer>
        <Toolbar onClick={setIsSidebarOpen} />
        <Menu isOpen={isSidebarOpen} onClick={setIsSidebarOpen} />
        <Hero />
        <BottomLine />
        <About />
        <BottomLine />
        <Guidance onClick={setIsSidebarOpen} />
        <BottomLine ref={scrollRef} />
        <BookClub />
        <Footer />
      </MainContainer>
    </ParallaxProvider>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f4e7;
  max-width: 2000px;
`;

export default App;
