import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import useIsInViewport from 'use-is-in-viewport';

export const Section = ({
  sectionNumber,
  sectionHeader,
  sectionText,
  ...props
}) => {
  return (
    <StyledGrid>
      <ChapterNumber>
        <span>{sectionNumber}</span>
        <StyledLine />
      </ChapterNumber>
      <ChapterName>{sectionHeader}</ChapterName>
      <StyledHeadingPosition>{sectionText}</StyledHeadingPosition>
      <VerticalLine />
      <SectionContent>{props.children}</SectionContent>
    </StyledGrid>
  );
};

const slidedownNew = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -120%);
  }
  to { 
    opacity: 1; 
  }
`;

export const StyledGrid = styled.div`
  font-size: 42px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  padding-bottom: 1em;
  padding-top: 1em;

  @media only screen and (min-width: 1024px) {
    font-size: 72px;
    grid-column-gap: 25px;
    margin-right: 0.7em;
    margin-left: 0.7em;
  }
`;

const ChapterNumber = styled.div`
  grid-row-start: 1;
  grid-column-start: 2;
  font-family: 'Canela-ThinItalic';
  font-size: 0.7em;
  font-weight: 100;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.66;
  letter-spacing: normal;
  color: #141414;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    grid-column-start: 1;
  }
`;

const StyledLine = styled.div`
  width: 0.6em;
  height: 1px;
  background-color: #141414;
  margin-right: 0.36em;
  @media only screen and (min-width: 1024px) {
    margin-left: 0.36em;
  }
`;

const ChapterName = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 13;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: TTNormsPro;
  font-size: 0.22em;
  letter-spacing: 3px;
  color: #141414;
`;

const SectionContent = styled.div`
  grid-row-start: 4;
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeadingText = styled.div`
  font-family: Canela-Thin;
  font-size: 1em;
  font-weight: 100;
  line-height: 1;
  color: #141414;
  > span {
    display: flex;
    overflow: hidden;
    justify-content: center;
  }
`;

const StyledHeadingPosition = styled.div`
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 2;
  margin-top: 0.76em;
`;

export const BottomLine = styled.div`
  width: 100vw;
  max-width: 2000px;
  height: 2px;
  background-color: black;
  @media only screen and (min-width: 1024px) {
  }
`;

export const VerticalLine = () => {
  const [isViewed, setisViewed] = useState(false);
  const [isInViewport, targetRef] = useIsInViewport();

  useEffect(() => {
    if (isInViewport) setisViewed(true);
  }, [isInViewport]);
  return (
    <StyledVerticalLine ref={targetRef}>
      {isViewed && <div></div>}
    </StyledVerticalLine>
  );
};

const StyledVerticalLine = styled.div`
  overflow: hidden;
  grid-row-start: 3;
  grid-column-start: 2;
  grid-column-end: 12;
  justify-self: center;
  margin-top: 0.76em;
  margin-bottom: 0.76em;
  animation-fill-mode: forwards;

  div {
    opacity: 1;
    height: 55px;
    width: 1px;
    background-color: #141414;
    /* animation: ${slidedownNew} 1.5s;
    animation-delay: 2s;
    animation-fill-mode: forwards; */
  }

  @media only screen and (min-width: 1024px) {
    height: 0.76em;
  }
`;
