import React from 'react';
import styled from 'styled-components';
import { StyledGrid, StyledHeadingText, VerticalLine } from './Section';
import CloseIcon from './close.svg';
import { TextOverlay, UnderlinedText } from './About';
import useIsInViewport from 'use-is-in-viewport';
import { AnimatedTextLine } from './Hero';

const Menu = ({ isOpen, onClick }) => {
  const [isViewed, setisViewed] = React.useState(false);
  const [isInViewport, targetRef] = useIsInViewport();

  React.useEffect(() => {
    if (isInViewport) setisViewed(true);
  }, [isInViewport]);
  return (
    <StyledMenu open={isOpen}>
      <CloseButton onClick={() => onClick(false)}>
        <img ref={targetRef} src={CloseIcon} alt='Back to web' />
      </CloseButton>
      {isViewed && (
        <StyledHeadingText>
          <span>
            <AnimatedTextLine>
              Let's{' '}
              <UnderlinedText>
                {<TextOverlay />}
                collaborate
              </UnderlinedText>
              .
            </AnimatedTextLine>
          </span>
        </StyledHeadingText>
      )}
      <VerticalLine />
      <Quote>
        "The work of today is the history of tomorrow and we are its makers."
      </Quote>
      <QuoteAttribution>- Juliette Gordon Low</QuoteAttribution>
      <MailAddress
        onClick={() => window.open('mailto:meymona@meymona.com', '_href')}
      >
        meymona@meymona.com
      </MailAddress>
    </StyledMenu>
  );
};
export default Menu;

const CloseButton = styled(StyledGrid)`
  cursor: pointer;
  font-size: 0.3em;
  position: absolute;
  height: 90px;
  right: 0;
  left: 0;
  top: 0;
  img {
    justify-self: center;
    align-self: center;
    height: 2em;
    width: 2em;
    grid-column-start: 10;
    grid-column-end: 12;
    transition: all 1s;
    :hover {
      height: 1.5em;
      width: 1.5em;
    }
    @media only screen and (min-width: 1024px) {
      grid-column-start: 11;
      grid-column-end: 13;
    }
  }
  @media only screen and (min-width: 1024px) {
    height: 90px;
  }
`;
const Quote = styled.div`
  font-family: TTNormsPro;
  font-size: 0.27em;
  line-height: 1.65;
  font-weight: normal;
`;
const MailAddress = styled.div`
  margin-top: 0.27em;
  font-family: Canela-Thin;
  font-size: 0.77em;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.84;
  cursor: pointer;
`;
const QuoteAttribution = styled.div`
  font-family: TTNormsPro;
  font-size: 0.2em;
  font-weight: normal;
  font-style: italic;
  line-height: 2.2;
`;
export const StyledMenu = styled.nav`
  color: #1e1e1e;
  font-size: 42px;
  display: flex;
  z-index: 900;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30%;
  background: #f5f4e7;
  height: 100vh;
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  @media only screen and (min-width: 1024px) {
    padding-top: 20%;
    font-size: 72px;
  }
`;
