import React from 'react';
import styled from 'styled-components';
import { StyledGrid } from './Section';

export const Footer = () => {
  return (
    <Container>
      <SocialNetwork>
        <SocialMedia
          onClick={() =>
            window.open('https://www.linkedin.com/in/meymona/', '_href')
          }
        >
          LinkedIn
        </SocialMedia>
        {/* <SocialMedia>Medium</SocialMedia> */}
      </SocialNetwork>
      <ContactMail>
        <SocialMedia
          onClick={() => window.open('mailto:meymona@meymona.com', '_href')}
        >
          meymona@meymona.com
        </SocialMedia>
      </ContactMail>
      <CopyRight>© {new Date().getFullYear()} All Rights Reserved</CopyRight>
    </Container>
  );
};

const Container = styled(StyledGrid)`
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: TTNormsPro;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
`;

const SocialNetwork = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
  justify-self: center;
  align-self: center;
  @media only screen and (min-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const SocialMedia = styled.div`
  cursor: pointer;
  padding-top: 1em;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.65;
  letter-spacing: 3px;
  transition: 0.8s;
  :hover {
    letter-spacing: -1px;
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 0em;
  }
`;
const ContactMail = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 2;
  justify-self: center;
  align-self: center;
  @media only screen and (min-width: 1024px) {
    grid-row-start: 1;
    grid-column-start: 10;
    grid-column-end: 12;
  }
`;

const CopyRight = styled.div`
  grid-row-start: 3;
  grid-column-start: 2;
  grid-column-end: 12;

  font-size: 12px;
  color: #858585;
  line-height: 2.75;
  justify-self: center;
  margin-top: 30px;
  @media only screen and (min-width: 1024px) {
    grid-row-start: 2;
  }
`;
