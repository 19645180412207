import React from 'react';
import styled, { keyframes } from 'styled-components';
import useIsInViewport from 'use-is-in-viewport';
import { AnimatedTextLine } from './Hero';
import { Section, StyledHeadingText } from './Section';
import useWindowDimensions from './useWindowPosition';

export const About = () => {
  const [isViewed, setisViewed] = React.useState(false);
  const [isInViewport, targetRef] = useIsInViewport();
  const { width } = useWindowDimensions();
  React.useEffect(() => {
    if (isInViewport) setisViewed(true);
  }, [isInViewport]);

  return (
    <div>
      <Section
        sectionNumber='01'
        sectionHeader='about'
        sectionText={
          width > 1080
            ? (isViewed && (
                <StyledHeadingText>
                  <span>
                    <AnimatedTextLine delay={100}>Meymona </AnimatedTextLine>
                  </span>
                  <span>
                    <AnimatedTextLine delay={250}>
                      is a
                      <span
                        style={{
                          fontStyle: 'italic',
                        }}
                      >
                        {' '}
                        community builder,
                      </span>
                    </AnimatedTextLine>
                  </span>
                  <span>
                    <AnimatedTextLine delay={400}>
                      {' '}
                      DEI advocate and{' '}
                      <UnderlinedText>
                        {' '}
                        <TextOverlay />
                        steadfast friend.
                      </UnderlinedText>
                    </AnimatedTextLine>
                  </span>
                </StyledHeadingText>
              )) || <SpacerDiv height={3} />
            : (isViewed && (
                <StyledHeadingText>
                  <span>
                    <AnimatedTextLine delay={100}>Meymona </AnimatedTextLine>
                  </span>
                  <span>
                    <AnimatedTextLine delay={250}>
                      is a
                      <span style={{ fontStyle: 'italic' }}> community</span>
                    </AnimatedTextLine>
                  </span>
                  <span>
                    <AnimatedTextLine delay={250}>
                      <span style={{ fontStyle: 'italic' }}> builder,</span>
                    </AnimatedTextLine>
                  </span>
                  <span>
                    <AnimatedTextLine delay={400}>
                      {' '}
                      DEI advocate and{' '}
                    </AnimatedTextLine>
                  </span>
                  <span>
                    <AnimatedTextLine delay={400}>
                      <UnderlinedText>
                        {' '}
                        <TextOverlay />
                        steadfast friend.
                      </UnderlinedText>
                    </AnimatedTextLine>
                  </span>
                </StyledHeadingText>
              )) || <SpacerDiv height={5} />
        }
      >
        <TextContainer>
          <div ref={targetRef} style={{ marginRight: '24px' }}>
            <div>
              Meymona Haithar Abdi is a Kenyan Somali-American leader passionate
              about supporting diverse communities and amplifying
              underrepresented voices.
            </div>
            <div>
              She is a seasoned expert with 20 years of experience in corporate
              finance, investments and international relations across the United
              States, Europe and Africa.{' '}
            </div>
          </div>
          <div>
            <div>
              With her passion for advancing diversity, equity and inclusion,
              Meymona’s vision is to create more opportunities for
              underrepresented communities in the workforce.
            </div>
            <div>
              Meymona resides in NYC where she enjoys being a forever bookworm,
              attending intimate dinner parties and learning to cook global
              cuisines- always with a side of{' '}
              <span style={{ fontStyle: 'italic' }}>moos</span>.
            </div>
          </div>
        </TextContainer>
      </Section>
    </div>
  );
};

export const SpacerDiv = styled.div`
  height: ${props => props.height}em;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: TTNormsPro;
  line-height: 1.65;
  font-size: 17px;
  color: #1e1e1e;
  @media only screen and (min-width: 1024px) {
    font-size: 20px;
    flex-direction: row;
    justify-content: space-evenly;
  }

  div {
    flex: 1;
    div {
      flex: 1;
    }
    ul {
      padding: 0;
      margin: 0;
      padding-left: 20px;
    }
    margin-bottom: 1em;
    @media only screen and (min-width: 1024px) {
      margin-bottom: 35px;
    }
  }
`;

const slide = keyframes`
  from {
    opacity: 0;
    width: 0%;
    z-index: 0;
  }
  to { 
    width: 100%;
    opacity: 1;
    z-index: 5; 
  }
`;
export const TextOverlay = styled.div`
  position: absolute;
  opacity: 0;
  right: 0;
  left: 0;
  bottom: 1px;
  background-color: black;
  height: 1px;
  width: 0%;
  animation: ${slide} 3s forwards;
  animation-delay: 2s;
`;

export const UnderlinedText = styled.span`
  position: relative;
  overflow: hidden;
`;
