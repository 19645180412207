import React from 'react';
import styled from 'styled-components';
import { StyledGrid } from './Section';
import useWindowDimensions from './useWindowPosition';

export const Toolbar = ({ onClick }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [isTop, setIsTop] = React.useState(true);
  const { height } = useWindowDimensions();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (scrollPosition > height) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  }, [scrollPosition, height]);

  return (
    <StyledContainer>
      <Meymona isVisible={!isTop}>
        Meym<span style={{ fontFamily: 'Canela-ThinItalic' }}>o</span>na
      </Meymona>
      <Contact onClick={() => onClick(true)}>Get in Touch</Contact>
    </StyledContainer>
  );
};

const Meymona = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  justify-self: center;
  align-self: center;
  font-family: 'Canela-Light';
  font-weight: 300;
  letter-spacing: -1.02px;
  color: #1e1e1e;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in;
  @media only screen and (min-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const StyledContainer = styled(StyledGrid)`
  font-size: 20px;
  z-index: 20;
  left: 0;
  right: 0;
  -webkit-backface-visibility: hidden;
  position: fixed;
  height: 60px;
  background-color: transparent;
  @media only screen and (min-width: 1024px) {
    font-size: 30px;
    height: 90px;
  }
`;

const Contact = styled.div`
  grid-column-start: 6;
  grid-column-end: 12;
  align-self: center;
  justify-self: right;
  text-transform: uppercase;
  font-family: TTNormsPro;
  font-size: 0.71em;
  letter-spacing: 3px;
  cursor: pointer;
  @media only screen and (min-width: 1024px) {
    justify-self: center;
    transition: 0.8s;
    font-size: 0.61em;
    grid-column-start: 11;
    grid-column-end: 13;
    :hover {
      letter-spacing: -1px;
    }
  }
`;
