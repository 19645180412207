import React from 'react';
import styled, { keyframes } from 'styled-components';
import { StyledGrid } from './Section';
import { Parallax } from 'react-scroll-parallax';
import MeyPhoto from './meymona_3.jpg';

export const Hero = () => {
  return (
    <StyledContainer>
      <TitleContainer>
        <Parallax y={['0px', '0px']}>
          <Meymona>
            Meym
            <span style={{ fontFamily: 'Canela-ThinItalic' }}>o</span>
            na
          </Meymona>
        </Parallax>
      </TitleContainer>
      <Image>
        <Parallax y={[15, -10]}>
          <FakeImg src={MeyPhoto}></FakeImg>
        </Parallax>
      </Image>
      <DisclaimerPosition>
        <DisclaimerText>
          <span>
            <AnimatedTextLine delay={100}>
              Only{' '}
              <span style={{ fontFamily: 'Canela-ThinItalic' }}>together</span>{' '}
            </AnimatedTextLine>
          </span>
          <span>
            <AnimatedTextLine delay={250}>we can make </AnimatedTextLine>
          </span>
          <span>
            <AnimatedTextLine delay={400}>a true difference.</AnimatedTextLine>
          </span>
        </DisclaimerText>
      </DisclaimerPosition>
    </StyledContainer>
  );
};

const animation = keyframes` {
  from { transform: translate3d(0,105%, 0)}
  to { transform: translate3d(0,0%, 0)}
}`;

export const StyledContainer = styled(StyledGrid)`
  font-size: 77px;
  padding-top: 1em;
  @media only screen and (min-width: 1024px) {
    padding-top: 0.2em;
    margin-left: 0.2em;
    margin-right: 0.2em;
    font-size: 250px;
    padding-bottom: 0.4em;
  }
`;
const TitleContainer = styled.div`
  grid-row-start: 1;
  grid-column-start: 2;
  grid-column-end: 12;
  display: flex;
  z-index: 15;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 13;
  }
`;

const Meymona = styled.div`
  font-family: 'Canela-Light';
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.04em;
  color: #1e1e1e;
  z-index: 10;
`;

const Image = styled.div`
  position: relative;
  grid-column-start: 2;
  grid-column-end: 10;
  grid-row-start: 2;
  flex: 1;
  @media only screen and (min-width: 1024px) {
    margin-top: -60px;
    height: 559px;
    grid-column-start: 1;
    grid-column-end: 6;
  }
`;

const FakeImg = styled.img`
  height: 220px;
  @media only screen and (min-width: 1024px) {
    height: 559px;
  }
`;

const DisclaimerPosition = styled.div`
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 3;
  @media only screen and (min-width: 1024px) {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-column-end: 12;
  }
`;

const DisclaimerText = styled.p`
  font-size: 0.64em;
  font-family: 'Canela-Thin';
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #141414;
  text-align: right;

  > span {
    display: block;
    overflow: hidden;
    float: right;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 0.32em;
  }
`;

export const AnimatedTextLine = styled.span`
  padding-right: 5px;
  float: inherit;
  -webkit-transform: translate3d(0, 105%, 0);
  animation: ${animation} 2s;
  animation-fill-mode: forwards;
  animation-delay: ${props => props.delay}ms;
  animation-timing-function: cubic-bezier(0.32, 1.02, 0.42, 0.91);
`;
