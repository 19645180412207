import React from 'react';
import styled from 'styled-components';
import useIsInViewport from 'use-is-in-viewport';
import { TextContainer, UnderlinedText, TextOverlay, SpacerDiv } from './About';
import { AnimatedTextLine } from './Hero';
import { Section, StyledHeadingText } from './Section';
import useWindowDimensions from './useWindowPosition';

export const Guidance = ({ onClick }) => {
  const [isViewed, setisViewed] = React.useState(false);
  const [isInViewport, targetRef] = useIsInViewport();
  const { width } = useWindowDimensions();

  React.useEffect(() => {
    if (isInViewport) setisViewed(true);
  }, [isInViewport]);
  return (
    <Section
      sectionNumber='02'
      sectionHeader='guidance'
      sectionText={
        width > 1080
          ? (isViewed && (
              <StyledHeadingText>
                <span>
                  <AnimatedTextLine delay={100}>
                    <span style={{ fontStyle: 'italic' }}>Coaching</span>
                  </AnimatedTextLine>
                </span>
                <span>
                  <AnimatedTextLine delay={250}>
                    with intentionality,
                  </AnimatedTextLine>
                </span>
                <span>
                  <AnimatedTextLine delay={400}>
                    alignment and
                    <UnderlinedText>
                      {isViewed && <TextOverlay />} purpose.
                    </UnderlinedText>
                  </AnimatedTextLine>
                </span>
              </StyledHeadingText>
            )) || <SpacerDiv height={3} />
          : (isViewed && (
              <StyledHeadingText>
                <span>
                  <AnimatedTextLine delay={100}>
                    <span style={{ fontStyle: 'italic' }}>Coaching</span>
                  </AnimatedTextLine>
                </span>
                <span>
                  <AnimatedTextLine delay={250}>
                    with intentionality,
                  </AnimatedTextLine>
                </span>
                <span>
                  <AnimatedTextLine delay={400}>alignment and</AnimatedTextLine>
                </span>
                <span>
                  <AnimatedTextLine delay={400}>
                    <UnderlinedText>
                      {isViewed && <TextOverlay />} purpose.
                    </UnderlinedText>
                  </AnimatedTextLine>
                </span>
              </StyledHeadingText>
            )) || <SpacerDiv height={4} />
      }
    >
      <TextContainer>
        <div ref={targetRef} style={{ marginRight: '24px' }}>
          <div>
            For many, life can feel repetitive, stagnant and full of obstacles-
            sentiments that are familiar to Meymona and the community she
            guides.
          </div>
          <div>
            Through a holistic and customized approach, Meymona supports
            established and emerging leaders to rediscover, align and build
            towards a more meaningful life.
          </div>
        </div>
        <div>
          <Credentials>
            *Meymona is a Certified Professional Coach (CPC) and Energy
            Leadership Index™ Master Practitioner (ELI-MP) with the Institute
            for Professional Excellence in Coaching (iPEC).
          </Credentials>
          <div>
            <TextSubtitle>Expertise</TextSubtitle>
            <ul>
              <li>Leadership Development</li>
              <li>Career Development</li>
              <li>Career Transition</li>
              <li>Executive Coaching</li>
              <li>Life Coaching</li>
            </ul>
          </div>
        </div>
      </TextContainer>
      <div style={{ paddingBottom: '0.5em' }}>
        <StyledButton onClick={() => onClick(true)}>Let's Begin</StyledButton>
      </div>
    </Section>
  );
};

export const TextSubtitle = styled.h1`
  font-family: Canela-Thin;
  font-size: 27px;
  color: #141414;
  font-weight: 100;
  margin: 0;
  @media only screen and (min-width: 1024px) {
    font-size: 27px;
  }
`;
const Credentials = styled.div`
  font-size: 13px;
  @media only screen and (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const StyledButton = styled.button`
  cursor: pointer;
  width: 263px;
  height: 59px;
  grid-row-start: 5;
  grid-column-start: 1;
  grid-column-end: 12;
  justify-self: center;
  font-family: TTNormsPro;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #141414;
  transition: 0.8s;
  animation-delay: 500ms;
  background-color: transparent;
  border-radius: 130px / 30px;
  border: 1px solid black;
  :focus {
    outline: none !important;
    box-shadow: none;
  }
  :hover {
    letter-spacing: -1px;
  }
`;
