import React from 'react';
import styled from 'styled-components';
import { SpacerDiv, TextContainer, TextOverlay, UnderlinedText } from './About';
import { TextSubtitle } from './Guidance';
import { Section, StyledHeadingText } from './Section';
import RightArrow from './arrow mail.svg';
import useIsInViewport from 'use-is-in-viewport';
import { AnimatedTextLine } from './Hero';

export const BookClub = () => {
  const [isViewed, setisViewed] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isInViewport, targetRef] = useIsInViewport();
  const [placeholderText, setPlaceholderText] = React.useState(
    `E-MAIL ADDRESS`
  );

  const handleSubmit = e => {
    e.preventDefault();

    setEmail('');
    setIsDisabled(true);
    setPlaceholderText('Sending email... Thanks for waiting.');
    fetch(
      'https://script.google.com/macros/s/AKfycbwyspvjpAHwonEGm70_IuXWaZ1_dzrAhjcMTq2VB6zIo9cNSKe3UOVz4g/exec?' +
        new URLSearchParams({
          email,
        }),
      {
        method: 'GET',
        redirect: 'follow',
      }
    ).then(res => {
      if (res.status === 200) {
        setPlaceholderText('E-mail address successfully submitted.');
      } else {
        setIsDisabled(false);
        setPlaceholderText('SOMETHING WENT WRONG, PLEASE RETRY');
        // ERROR
      }
    });
  };

  React.useEffect(() => {
    if (isInViewport) setisViewed(true);
  }, [isInViewport]);
  return (
    <div>
      <Section
        sectionNumber='03'
        sectionHeader='The book club'
        sectionText={
          (isViewed && (
            <StyledHeadingText>
              <span>
                <AnimatedTextLine>Better</AnimatedTextLine>
              </span>
              <span>
                <AnimatedTextLine>
                  <span style={{ fontStyle: 'italic' }}>All </span>
                  <UnderlinedText> {<TextOverlay />} Together</UnderlinedText>
                </AnimatedTextLine>
              </span>
            </StyledHeadingText>
          )) || <SpacerDiv height={2} />
        }
      >
        <TextContainer>
          <div ref={targetRef} style={{ marginRight: '24px' }}>
            <div>
              Better All Together is a community rooted in collective growth and
              betterment through an inclusive lens.
            </div>
            <div>
              Starting with the book club, the vision is to create meaningful
              dialogue and a space to address, advocate and advance all voices
              forward.
            </div>
          </div>
          <div>
            <TextSubtitle>Please join us here.</TextSubtitle>
            <MailInputGroup onSubmit={e => handleSubmit(e)}>
              <StyledInput
                type='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={placeholderText}
                disabled={isDisabled}
              ></StyledInput>
              <button type='submit' disabled={isDisabled}>
                <img src={RightArrow} alt='Subscribe to mailing list'></img>
              </button>
            </MailInputGroup>
            <DisclaimerText>
              Transparency is important. We respect all participation,
              information and communication. Content shared will include
              invitations, newsletter and updates for the Better All Together
              community.
            </DisclaimerText>
          </div>
        </TextContainer>
      </Section>
    </div>
  );
};

const DisclaimerText = styled.div`
  font-family: TTNormsPro;
  font-size: 11px;
  line-height: 1.45;
  color: #878787;
`;

const StyledInput = styled.input`
  height: 48px;
  width: calc(87% - 14px);
  background-color: #e2d5a3;
  border: none;
  border-radius: 0;
  font-family: TTNormsPro;
  font-size: 15px;
  padding-left: 14px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: black;

  :focus {
    outline: none !important;
    border: none;
    box-shadow: none;
  }

  ::placeholder {
    color: #696969;
  }
`;
const MailInputGroup = styled.form`
  margin-top: 19px;
  margin-bottom: 34px;
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
    background-color: #e2d5a3;
    border: none;
    height: 48px;
    margin-left: -50px;
    :focus {
      outline: none !important;
      border: none;
      box-shadow: none;
    }
    :hover {
      img {
        transform: translate(10%);
      }
    }
    img {
      height: 50px;
      width: 24px;
      transition: all 0.3s;
    }
  }
`;
